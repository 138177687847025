<template>
    <v-container fluid>
        <v-row>
            <v-col cols="8">
                <trev-cool-table ref="tbl" :courier="$courier.ScopeGroups" :headers="headers">
                    <template #title>
                        <v-icon class="mr-2">
                            mdi-account-multiple-outline
                        </v-icon>
                        Scope Groups
                    </template>
                    <template #item.actions="{ item }">
                        <v-btn color="primary" small @click="showGroup(item)" class="mr-2"
                            :loading="isLoadingScopes && loadingScopeGroupId === item.id">
                            <v-icon class="mr-2">
                                mdi-magnify
                            </v-icon>
                            View Scopes
                        </v-btn>
                        <v-btn small color="primary" :to="{ name: 'View Group', params: { scopeGroupId: item.id } }" class="mr-2">
                            <v-icon class="mr-2">
                                mdi-folder-key-outline
                            </v-icon>
                            View Group
                        </v-btn>
                        <v-btn color="danger" small @click="removeGroup(item)">
                            <v-icon class="mr-2">
                                mdi-delete
                            </v-icon>
                            Delete
                        </v-btn>
                    </template>
                </trev-cool-table>
            </v-col>
            <v-col>
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-plus
                            </v-icon>
                            Add Group
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field label="New Group Name" v-model="newGroupName" @keydown.enter="addNewGroup"
                            :error="!!errorMessage" :error-messages="errorMessage">
                        </v-text-field>
                        <v-btn color="primary" small @click="addNewGroup">
                            <v-icon class="mr-2">
                                mdi-save
                            </v-icon>
                            Add New Grop
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog max-width="60vh" v-model="isShowingGroup">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <v-icon class="mr-2">
                            mdi-clipboard-account-outline
                        </v-icon>
                        Group: {{ selectedGroup.name }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-list>
                    <v-list-item v-for="scope in selectedGroup.scopes" :key="scope.id">
                        <v-list-item-title>
                            {{ scope.scope }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
        <trev-confirm-delete-dialog :itemToDelete="groupToDelete.name" @delete="deleteGroup" @cancel="cancelDeleteGroup" :show.sync="isShowingDeleteGroup">
        </trev-confirm-delete-dialog>
    </v-container>
</template>

<script>
import TrevConfirmDeleteDialog from '../../../../components/base/dialog/trev-confirm-delete-dialog.vue';
import trevCoolTable from '../../../../components/tables/trev-cool-table.vue'
export default {
    components: { trevCoolTable, TrevConfirmDeleteDialog },
    methods: {
        removeGroup(group){
            this.groupToDelete = group;
            this.isShowingDeleteGroup = true;
        },
        deleteGroup(){
            this.$courier.ScopeGroups.removeById(this.groupToDelete.id).then(() => {
                this.cancelDeleteGroup();
                this.$refs.tbl.getData();
            });
        },
        cancelDeleteGroup(){
            this.groupToDelete = {};
            this.isShowingDeleteGroup = false;
        },
        addNewGroup() {
            //check to see if exists

            if (this.newGroupName) {
                this.$courier.ScopeGroups.getData(1, 200, this.newGroupName).then(data => {
                    if (data && data.payload && data.payload.length > 0 && data.payload.some(x => x.name === this.newGroupName)) {
                        this.errorMessage = 'Group with name: ' + this.newGroupName + ' exists already.';
                    }
                    else {
                        this.$courier.ScopeGroups.add({ name: this.newGroupName }).then(() => {
                            this.$refs.tbl.getData();

                            this.newGroupName = '';
                        })
                    }
                });
            }
            else {
                this.errorMessage = 'Invalid Group name';
            }
        },
        showGroup(group) {
            this.isLoadingScopes = true;
            this.loadingScopeGroupId = group.id;
            this.$courier.ScopeGroups.withId(group.id).Scopes.getData(1, 99999, '').then(scopes => {
                this.selectedGroup = group;

                if (scopes && scopes.payload) {
                    this.selectedGroup.scopes = scopes.payload;
                }

                this.isLoadingScopes = false;
                this.loadingScopeGroupId = '';
                this.isShowingGroup = true;
            });
        }
    },
    data() {
        return {
            groupToDelete: {},
            isShowingDeleteGroup: false,
            loadingScopeGroupId: '',
            isLoadingScopes: false,
            isShowingGroup: false,
            selectedGroup: {},
            newGroupName: '',
            errorMessage: '',
            headers: [
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Actions',
                    value: 'actions'
                }
            ]
        }
    },
}
</script>

<style></style>